import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const allImages = [
    "https://i.ibb.co/wQshwRM/3.webp",
    "https://i.ibb.co/qxppMTJ/Liberty-Jungle-Shoes-Black.webp",
    "https://i.ibb.co/wQshwRM/3.webp",
    "https://i.ibb.co/MkPBjz3/2.webp",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedImage((prevSelectedImage) =>
        prevSelectedImage < allImages.length - 1
          ? prevSelectedImage + 1
          : 0
      );
    }, 4000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [allImages.length]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <div className="bg-white relative">
      <Slider {...settings}>
        {allImages.map((image, index) => (
          <div key={index} className="h-400">
            <img
              width={400}
              height={200}
              src={image}
              alt={`Image ${index + 1}`}
              className="mx-auto h-full"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
