import Footer from "../../features/common/Footer.js";
import NavBar from "../../features/navbar/Navbar.js";
import Pricing from "../Quicklinks/Pricing.js";

export const PricingPolicyPage = () => {
  return (
    <div>
      <NavBar>
        <Pricing></Pricing>
      </NavBar>
      <Footer></Footer>
    </div>
  );
};
