import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-semibold mb-6">
        Senamart E-Commerce Terms and Conditions
      </h1>

      <p className="text-sm text-gray-600">Last Updated: 30-01-2024</p>

      <p className="text-gray-700 my-6">
        Please read these terms and conditions carefully before using
        senamart.in.
      </p>

      <h2 className="text-xl font-semibold my-4">1. Acceptance of Terms</h2>

      <p className="text-gray-700">
        By accessing or using Senamart (the "Website"), you agree to be bound by
        these Terms and Conditions, our Privacy Policy, and all applicable laws
        and regulations. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site.
      </p>

      <h2 className="text-xl font-semibold my-4">2. User Accounts</h2>

      <p className="text-gray-700">
        a. In order to make a purchase on senamart.in, you may be required to
        create a user account. You are responsible for maintaining the
        confidentiality of your account information.
      </p>

      <p className="text-gray-700">
        b. You agree to provide accurate, current, and complete information
        during the registration process and to update such information to keep
        it accurate, current, and complete.
      </p>

      <h2 className="text-xl font-semibold my-4">3. Products and Pricing</h2>

      <p className="text-gray-700">
        a. All product listings on senamart.in are subject to availability. We
        reserve the right to discontinue any product at any time.
      </p>

      <p className="text-gray-700">
        b. Prices are listed in your currency and are subject to change without
        notice.
      </p>

      <h2 className="text-xl font-semibold my-4">4. Orders and Payments</h2>

      <p className="text-gray-700">
        a. When you place an order on senamart.in, you are making an offer to
        purchase the products in your shopping cart. We reserve the right to
        accept or reject any order.
      </p>

      <p className="text-gray-700">
        b. Payment can be made using accepted payment methods. Your payment
        information will be processed securely.
      </p>

      <h2 className="text-xl font-semibold my-4">5. Shipping and Delivery</h2>

      <p className="text-gray-700">
        a. Shipping costs and delivery times vary based on your location and the
        shipping method selected at checkout.
      </p>

      <p className="text-gray-700">
        b. We are not responsible for any delays or issues caused by third-party
        shipping carriers.
      </p>

      <h2 className="text-xl font-semibold my-4">6. Returns and Refunds</h2>

      <p className="text-gray-700">
        a. Please review our Return and Refund Policy for information on returns
        and refunds.
      </p>

      <h2 className="text-xl font-semibold my-4">7. Privacy Policy</h2>

      <p className="text-gray-700">
        a. Your privacy is important to us. Please review our Privacy Policy to
        understand how we collect, use, and disclose your personal information.
      </p>

      <h2 className="text-xl font-semibold my-4">8. Intellectual Property</h2>

      <p className="text-gray-700">
        a. All content on senamart.in, including text, images, logos, and
        graphics, is the property of Senamart and is protected by intellectual
        property laws.
      </p>

      <h2 className="text-xl font-semibold my-4">9. Limitation of Liability</h2>

      <p className="text-gray-700">
        a. Senamart shall not be liable for any direct, indirect, incidental,
        special, or consequential damages resulting from the use or inability to
        use senamart.in or the products purchased.
      </p>

      <h2 className="text-xl font-semibold my-4">10. Governing Law</h2>

      <p className="text-gray-700">
        a. These terms and conditions are governed by the laws of your
        country/state.
      </p>

      <h2 className="text-xl font-semibold my-4">
        11. Changes to Terms and Conditions
      </h2>

      <p className="text-gray-700">
        a. Senamart reserves the right to update or modify these terms and
        conditions at any time without prior notice. The latest version will be
        posted on senamart.in.
      </p>
    </div>
  );
};

export default TermsAndConditions;
// import React from 'react';

// const TermsAndConditions = () => {
//   return (
//     <div className="container mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
//       <h1 className="text-3xl font-semibold mb-6">Terms and Conditions</h1>

//       <p className="text-gray-700">
//         Welcome to Senamart! By accessing or using our website, you agree to
//         comply with and be bound by the following Terms and Conditions of Use.
//         Please read these terms carefully before accessing or using our website.
//       </p>

//       <h2 className="text-xl font-semibold my-4">1. Acceptance of Terms</h2>

//       <p className="text-gray-700">
//         By accessing or using Senamart (the "Website"), you agree to be bound by
//         these Terms and Conditions, our Privacy Policy, and all applicable laws
//         and regulations. If you do not agree with any of these terms, you are
//         prohibited from using or accessing this site.
//       </p>

//       {/* Add more sections based on the structure above */}

//       <h2 className="text-xl font-semibold my-4">8. Governing Law</h2>

//       <p className="text-gray-700">
//         These terms and conditions are governed by and construed in accordance
//         with the laws of [Your Jurisdiction], and you irrevocably submit to the
//         exclusive jurisdiction of the courts in that State or location.
//       </p>

//       <h2 className="text-xl font-semibold my-4">9. Changes to Terms</h2>

//       <p className="text-gray-700">
//         Senamart may revise these terms of use for its website at any time
//         without notice. By using this website, you are agreeing to be bound by
//         the then-current version of these Terms and Conditions of Use.
//       </p>
//     </div>
//   );
// };

// export default TermsAndConditions;
