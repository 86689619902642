import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  loginUser,
  createUser,
  verifyOtp,
  signOut,
  checkAuth,
  resetPasswordRequest,
  resetPassword,
  locallog,
  resendOtp
} from './authAPI';
import { updateUser } from '../user/userAPI';

const initialState = {
  loggedInUserToken: null, // this should only contain user identity => 'id'/'role'
  status: 'idle',
  error: null,
  userChecked: false,
  mailSent: false,
  passwordReset:false,
  otpSent: false,     
  otpError: null, 
};

export const createUserAsync = createAsyncThunk(
  'user/createUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createUser(userData);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      // Use rejectWithValue to pass both the error message and the payload
      return rejectWithValue(error.message || 'Failed to create user');
    }
  }
);

export const resendOtpAsync = createAsyncThunk('user/resendotp', async (emaildata) => {
  const response = await resendOtp(emaildata);
  return response;
});



export const loginUserAsync = createAsyncThunk(
  'user/loginUser',
  async (loginInfo, { rejectWithValue }) => {
    try {
      const response = await loginUser(loginInfo);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// export const locallogasync = createAsyncThunk(
//   'user/verifyloginotp',
//   async (loginInfo, { rejectWithValue }) => {
//     try {
//       const response = await locallog(loginInfo);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);
//     }
//   }
// );
export const verifyOtpAsync = createAsyncThunk(
  'user/verifyOtp',
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await verifyOtp({email, otp});
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);


export const checkAuthAsync = createAsyncThunk('user/checkAuth', async () => {
  try {
    const response = await checkAuth();
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const resetPasswordRequestAsync = createAsyncThunk(
  'user/resetPasswordRequest',
  async (email,{rejectWithValue}) => {
    try {
      const response = await resetPasswordRequest(email);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);

    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'user/resetPassword',
  async (data,{rejectWithValue}) => {
    try {
      const response = await resetPassword(data);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);

    }
  }
);

export const signOutAsync = createAsyncThunk(
  'user/signOut',
  async () => {
    const response = await signOut();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);


export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.updates = action.payload;
        // state.loggedInUserToken = action.payload;    
      })
      .addCase(verifyOtpAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(verifyOtpAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = action.payload;
        state.otpError = null;
      })
      .addCase(verifyOtpAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.otpVerified = false;
        state.otpError = action.payload; 
      })
      .addCase(loginUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = action.payload;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      })
      // .addCase(locallogasync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(locallogasync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.loggedInUserToken = action.payload;
      // })
      // .addCase(locallogasync.rejected, (state, action) => {
      //   state.status = 'idle';
      //   state.error = action.payload;
      // })
      .addCase(signOutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signOutAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = null;
      })
      .addCase(checkAuthAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkAuthAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = action.payload;
        state.userChecked = true;
      })
      .addCase(checkAuthAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.userChecked = true;
      })
      .addCase(resetPasswordRequestAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPasswordRequestAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.mailSent = true;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.passwordReset = true;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload
      })
      .addCase(resendOtpAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resendOtpAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.resendotp = action.payload;
      })
      .addCase(resendOtpAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
      
      
  },
});
export const updates = (state) => state.auth.updates;
export const selectLoggedInUser = (state) => state.auth.loggedInUserToken;
export const selectError = (state) => state.auth.error;
export const selectUserChecked = (state) => state.auth.userChecked;
export const selectMailSent = (state) => state.auth.mailSent;
export const selectPasswordReset = (state) => state.auth.passwordReset;
export const selectOtpSent = (state) => state.auth.otpSent;
export const selectOtpVerified = (state) => state.auth.otpVerified;
export const selectOtpError = (state) => state.auth.otpError;

// export const { } = authSlice.actions;

export const selectresendotp = (state) => state.auth.resendotp;
export default authSlice.reducer;

// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import {
//   loginUser,
//   createUser,
//   signOut,
//   checkAuth,
//   resetPasswordRequest,
//   resetPassword,
//   verifyOtp,
//   resendOtpBro,        // Import the verifyOtp function
// } from './authAPI';
// // import { updateUser } from '../user/userAPI';
// // import { useSelector, useDispatch } from "react-redux";
// const initialState = {
//   loggedInUserToken: null,
//   status: 'idle',
//   error: null,
//   userChecked: false,
//   mailSent: false,
//   passwordReset: false,
//   otpSent: false,     // New state for tracking OTP sent
//   otpVerified: false, // New state for tracking OTP verification
//   otpError: null,     // New state for tracking OTP-related errors
// };


// export const createUserAsync = createAsyncThunk(
//   'user/createUser',
//   async ({ email, password, addresses, role, name }) => {

//       const response = await createUser({ email, password, addresses, role, name });
//       // const dispatch = useDispatch();
//       // dispatch(createUserSuccess(response.data));
//       console.log(response);
//       return response.data;
    
    
//   //   catch (error) {
//   //     return rejectWithValue(error.response ? error.response.data : error.message);
//   //   }

//   }
// );

// export const loginUserAsync = createAsyncThunk(
//   'user/loginUser',
//   async (loginInfo, { rejectWithValue }) => {
//     try {
//       const response = await loginUser(loginInfo);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const checkAuthAsync = createAsyncThunk('auth/checkAuth', async () => {
//   try {
//     const response = await checkAuth();
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// });

// export const resetPasswordRequestAsync = createAsyncThunk(
//   'user/resetPasswordRequest',
//   async (email, { rejectWithValue }) => {
//     try {
//       const response = await resetPasswordRequest(email);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const resetPasswordAsync = createAsyncThunk(
//   'user/resetPassword',
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await resetPassword(data);
//       console.log(response);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);
//     }
//   }
// );



// export const resendOtpAsync = createAsyncThunk(
//   'user/resendotp',
//   async ({ email }, { rejectWithValue }) => {
//     try {
//       const response = await resendOtpBro({email});
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.response ? error.response.data : error.message);
//     }
//   }
// );


// export const signOutAsync = createAsyncThunk(
//   'user/signOut',
//   async () => {
//     const response = await signOut();
//     return response.data;
//   }
// );

// export const authSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//   },
//   extraReducers: (builder) => {
//     builder
      
//       .addCase(createUserAsync.pending, (state) => {
//         state.status = 'loading';
//       })

//       .addCase(createUserAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.loggedInUserToken = action.payload;
//       })
//       .addCase(loginUserAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(loginUserAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.loggedInUserToken = action.payload;
//       })
//       .addCase(loginUserAsync.rejected, (state, action) => {
//         state.status = 'idle';
//         state.error = action.payload;
//       })
//       .addCase(signOutAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(signOutAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.loggedInUserToken = null;
//       })
//       .addCase(checkAuthAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(checkAuthAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.loggedInUserToken = action.payload;
//         state.userChecked = true;
//       })
//       .addCase(checkAuthAsync.rejected, (state, action) => {
//         state.status = 'idle';
//         state.userChecked = true;
//       })
//       .addCase(resetPasswordRequestAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(resetPasswordRequestAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.mailSent = true;
//       })
//       .addCase(resetPasswordAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(resetPasswordAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.passwordReset = true;
//       })
//       .addCase(resetPasswordAsync.rejected, (state, action) => {
//         state.status = 'idle';
//         state.error = action.payload;
//       })
      // .addCase(verifyOtpAsync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(verifyOtpAsync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.otpVerified = true;
      //   state.otpError = null;
      // })
      // .addCase(verifyOtpAsync.rejected, (state, action) => {
      //   state.status = 'idle';
      //   state.otpVerified = false;
      //   state.otpError = action.payload; // Consider refining the error information
//       })
//       .addCase(resendOtpAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(resendOtpAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.otpSent = true; // Set otpSent to true after successful resend
//       })
//       .addCase(resendOtpAsync.rejected, (state, action) => {
//         state.status = 'idle';
//         state.otpError = action.payload; // Consider refining the error information
//       });
//   },
// });



// export const selectLoggedInUser = (state) => state.auth.loggedInUserToken;
// export const selectError = (state) => state.auth.error;
// export const selectUserChecked = (state) => state.auth.userChecked;
// export const selectMailSent = (state) => state.auth.mailSent;
// export const selectPasswordReset = (state) => state.auth.passwordReset;
// export const selectOtpSent = (state) => state.auth.otpSent;
// export const selectOtpVerified = (state) => state.auth.otpVerified;
// export const selectOtpError = (state) => state.auth.otpError;

// export default authSlice.reducer;
