import Footer from "../../features/common/Footer.js";
import NavBar from "../../features/navbar/Navbar.js";
import PrivacyPolicy from "../Quicklinks/PrivacyPolicy.js";

function PrivacyPolicyPage() {
  return (
    <div>
      <NavBar>
        <PrivacyPolicy></PrivacyPolicy>
      </NavBar>
      <Footer></Footer>
    </div>
  );
}

export default PrivacyPolicyPage;
