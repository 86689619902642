import React from 'react';

const CancellationPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-lg shadow-md my-8">
      <h1 className="text-3xl font-semibold mb-6">Cancellation Policy</h1>

      <p className="text-gray-700 mb-6">
        At Senamart, we understand that sometimes you may need to cancel your order. Please review our cancellation policy below.
      </p>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Cancellation Conditions</h2>

        <p className="text-gray-700">
          You can only cancel your order if the status is 'Dispatched.' Once the order has been dispatched, no cancellation requests will be accepted. Please check your order status before attempting to cancel.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Refund Process</h2>

        <p className="text-gray-700">
          If you cancel your order and are eligible for a refund, the following process will be followed:
        </p>

        <ol className="list-decimal ml-6 text-gray-700">
          <li>
            <strong>Order Status:</strong> Your order status must be 'Dispatched' for cancellation to be considered.
          </li>
          <li>
            <strong>Cancellation Request:</strong> Submit a cancellation request through our website or contact our customer support team.
          </li>
          <li>
            <strong>Verification:</strong> Our team will verify the order status and eligibility for cancellation.
          </li>
          <li>
            <strong>Refund Processing:</strong> If eligible, your refund will be processed within 2 days.
          </li>
          <li>
            <strong>Payment Method:</strong> If you paid online, the refund will be credited to the original payment method used during the purchase.
          </li>
        </ol>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Contact Us</h2>

        <p>
          If you have any questions or concerns regarding our cancellation policies, feel free to reach out to our customer support team at <a className="text-blue-500 hover:underline" href="mailto:Jankii.international@gmail.com">Jankii.international@gmail.com</a>.
        </p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
