import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md my-8">
      <h1 className="text-3xl font-semibold mb-6">Refund Policy</h1>

      <p className="mb-4">
        At Senamart, we strive to provide the best possible service to our customers. If, for any reason, you are not satisfied with your purchase, we offer a hassle-free refund process. Please read our Refund Policy carefully for more information.
      </p>

      <h2 className="text-2xl font-semibold my-6">Cancellation and Returns</h2>

      <p className="mb-4">
        If you decide to cancel your order or initiate a return, you can do so within a specified period mentioned on the product page. Please note that certain products may have different return policies, and the duration for returns may vary.
      </p>

      <h2 className="text-2xl font-semibold my-6">Refund Process</h2>

      <p className="mb-4">
        After the successful cancellation or return of your product, your refund will be processed within 2 days. We will initiate the refund to your original payment method, and you will receive an email notification once the refund is processed.
      </p>

      <h2 className="text-2xl font-semibold my-6">Contact Us</h2>

      <p className="mb-4">
        If you have any questions about our Refund Policy or need assistance with the refund process, please contact our customer support team at <a className="text-blue-500 hover:underline" href="mailto:Jankii.international@gmail.com">Jankii.international@gmail.com</a>. We are here to help!
      </p>

      <p>
        Please note that our Refund Policy is subject to change, and any updates will be reflected on this page.
      </p>
    </div>
  );
};

export default RefundPolicy;
