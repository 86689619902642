import React from 'react';

const LoaderComponent = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-75 bg-gray-300 flex justify-center items-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      {/* You can replace the above div with your preferred spinner or loading message */}
    </div>
  );
};

export default LoaderComponent;
