import Footer from "../../features/common/Footer.js";
import NavBar from "../../features/navbar/Navbar.js";
import RefundPolicy from "../Quicklinks/RefundPolicy.js";

export const RefundPolicyPage = () => {
  return (
    <div>
      <NavBar>
        <RefundPolicy></RefundPolicy>
      </NavBar>
      <Footer></Footer>
    </div>
  );
};
