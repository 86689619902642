import TermsAndConditions from "../Quicklinks/TermsAndConditions.js";
import NavBar from "../../features/navbar/Navbar.js";
import Footer from "../../features/common/Footer.js";

export const TermsAndConditionsPage = () => {
    return ( 
        <div>
            <NavBar>
                <TermsAndConditions></TermsAndConditions>
            </NavBar>
            <Footer></Footer>
        </div>
     );
}