import Footer from "../../features/common/Footer.js";
import NavBar from "../../features/navbar/Navbar.js";
import AboutUs from "../Quicklinks/Aboutus.js";

function AboutUspage() {
  return (
    <div>
      <NavBar>
        <AboutUs></AboutUs>
      </NavBar>
      <Footer> </Footer>
    </div>
  );
}

export default AboutUspage;
