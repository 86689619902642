import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "react-loader-spinner";
import axios from "axios";
import Modal from "react-modal";
import noorderimage from "./noorder.jpg";

import {
  fetchLoggedInUserOrderAsync,
  selectUserInfoStatus,
  selectUserOrders,
} from "../userSlice";
// import { updateOrderAsync } from "../../order/orderSlice";
// import { set } from "react-hook-form";
Modal.setAppElement("#root");

export default function UserOrders() {
  const dispatch = useDispatch();
  const reduxOrders = useSelector(selectUserOrders);
  const status = useSelector(selectUserInfoStatus);
  useEffect(() => {
    // Fetch user orders when the component mounts
    dispatch(fetchLoggedInUserOrderAsync());
  }, [dispatch]);
  const [notification, setNotification] = useState(null);

  const [currentOrder, setCurrentOrder] = useState(null);
  const [returncurrentOrder, setreturncurrentOrder] = useState(null);

  const [orderModalStates, setOrderModalStates] = useState({});
  const openOrderModal = (itemId) => {
    setOrderModalStates((prevStates) => ({ ...prevStates, [itemId]: true }));
  };
  // Function to close the modal for a specific order
  const closeOrderModal = (itemId) => {
    setOrderModalStates((prevStates) => ({ ...prevStates, [itemId]: false }));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationConfirmed, setCancellationConfirmed] = useState(false);

  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [returnReason, setReturnReason] = useState("");
  const [returnConfirmed, setReturnConfirmed] = useState(false);

  // const openModal = () => {
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  // const handleReturnOrder = (orderId) => {
  //   setCurrentOrder(reduxOrders.find((order) => order.id === orderId));

  //   // Open the modal to capture the return reason
  //   setIsReturnModalOpen(true);
  // };

  const handleCancelOrder = async (orderId) => {
    setCurrentOrder(reduxOrders.find((order) => order.id === orderId));

    if (!isModalOpen) {
      // Open the modal to capture the cancellation reason
      setIsModalOpen(true);
    } else if (!cancellationConfirmed) {
      // Open the confirmation modal
      setCancellationConfirmed(true);
    } else {
      try {
        // Call your backend API to initiate the cancellation for the given orderId
        const response = await axios.post(`/api/cancel`, {
          orderId: currentOrder.id,
          cancellationReason: cancellationReason,
        });

        if (response.data.success) {
          console.log(
            `Cancellation initiated successfully for order with id ${orderId}`
          );

          // Display success notification and update the UI

          // Update the order in the state to mark it as cancelled
          // Update the order in the state to mark it as cancelled
          // const updatedOrders = reduxOrders.map((order) =>
          //   order.id === currentOrder.id
          //     ? {
          //         ...order,
          //         status: "cancelled",
          //         cancellationReason: cancellationReason,
          //       }
          //     : order
          // );

          // Use Promise.all to wait for all promises to resolve
          // await Promise.all(
          //   updatedOrders.map(async (updatedOrder) => {
          //     await dispatch(updateOrderAsync(updatedOrder));
          //     console.log(
          //       `Order with id ${updatedOrder.id} updated successfully.`
          //     );
          //   })
          // );

          setNotification({
            type: "success",
            message: response.data.message,
          });

          // Reset the current order
          setCurrentOrder(null);

          // Reset the cancellation reason
          setCancellationReason("");

          // Close the modal
          setIsModalOpen(false);
          setCancellationConfirmed(false);
        }
      } catch (error) {
        console.error("Failed to initiate cancellation:", error);

        // Display error notification
        setNotification({
          type: "error",
          message: "Failed to cancel order. Please try again.",
        });

        // Reset the current order
        setCurrentOrder(null);
      }
    }
  };
  const handleReturnOrder = async (orderId) => {
    setreturncurrentOrder(reduxOrders.find((order) => order.id === orderId));

    if (!isReturnModalOpen) {
      // Open the modal to capture the cancellation reason
      setIsReturnModalOpen(true);
    } else if (!returnConfirmed) {
      // Open the confirmation modal
      setReturnConfirmed(true);
    } else {
      try {
        console.log("return order id :", returncurrentOrder.id);
        console.log("return reason :", returnReason);
        // Call your backend API to initiate the cancellation for the given orderId
        const response = await axios.post(`/api/return`, {
          orderId: returncurrentOrder.id,
          returnReason: returnReason,
        });

        if (response.data.success) {
          console.log(
            `Return initiated successfully for order with id ${orderId}`
          );

          // Display success notification and update the UI

          // Update the order in the state to mark it as cancelled
          // Update the order in the state to mark it as cancelled
          // const updatedOrders = reduxOrders.map((order) =>
          //   order.id === currentOrder.id
          //     ? {
          //         ...order,
          //         status: "cancelled",
          //         cancellationReason: cancellationReason,
          //       }
          //     : order
          // );

          // Use Promise.all to wait for all promises to resolve
          // await Promise.all(
          //   updatedOrders.map(async (updatedOrder) => {
          //     await dispatch(updateOrderAsync(updatedOrder));
          //     console.log(
          //       `Order with id ${updatedOrder.id} updated successfully.`
          //     );
          //   })
          // );

          setNotification({
            type: "success",
            message: response.data.message,
          });

          // Reset the current order
          setreturncurrentOrder(null);

          // Reset the cancellation reason
          setReturnReason("");

          // Close the modal
          setIsReturnModalOpen(false);
          setReturnConfirmed(false);
        }
      } catch (error) {
        console.error("Failed to initiate return:", error);

        // Display error notification
        setNotification({
          type: "error",
          message: "Failed to cancel order. Please try again.",
        });

        // Reset the current order
        setreturncurrentOrder(null);
      }
    }
  };
  // const handleReturnConfirmation = async () => {
  //   try {
  //     // Call your backend API to initiate the return for the given orderId
  //     const response = await axios.post("http://localhost:8080/api/return", {
  //       orderId: currentOrder.id,
  //       returnReason: returnReason,
  //     });

  //     if (response.data.success) {
  //       console.log(
  //         `Return initiated successfully for order with id ${currentOrder.id}`
  //       );

  //       // Display success notification and update the UI
  //       setNotification({
  //         type: "success",
  //         message: response.data.message,
  //       });

  //       // Update the order in the state to mark it as returnInitiated
  //       const updatedOrders = reduxOrders.map((order) =>
  //         order.id === currentOrder.id
  //           ? { ...order, status: "returnInitiated" }
  //           : order
  //       );

  //       updatedOrders.forEach((updatedOrder) => {
  //         dispatch(updateOrderAsync(updatedOrder));
  //         console.log(`Order with id ${updatedOrder.id} updated successfully.`);
  //       });

  //       // Reset the current order
  //       setCurrentOrder(null);

  //       // Reset the return reason
  //       setReturnReason("");

  //       // Close the modal
  //       setIsReturnModalOpen(false);
  //       setReturnConfirmed(false);
  //     }
  //   } catch (error) {
  //     console.error("Failed to initiate return:", error);

  //     // Display error notification
  //     setNotification({
  //       type: "error",
  //       message: "Failed to initiate return. Please try again.",
  //     });

  //     // Reset the current order
  //     setCurrentOrder(null);
  //   }
  // };

  // const updateOrderStatus = (orderId, newStatus) => {
  //   // Update the status of the specific order in the local state
  //   const updatedOrders = reduxOrders.map((order) =>
  //     reduxOrders.id === orderId ? { ...order, status: newStatus } : order
  //   );

  //   // Set the updated orders in the local state
  //   setLocalOrders(updatedOrders);
  // };

  // const [localOrders, setLocalOrders] = useState([]);



  // useEffect(() => {
  //   // Update the local state with the received orders from Redux store
  //   setLocalOrders(reduxOrders);
  // }, [reduxOrders]);

  const isWithinSevenDays = (orderupdatedAt) => {
    const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const currentDate = new Date();
    const orderDate = new Date(orderupdatedAt);

    return currentDate - orderDate <= sevenDaysInMillis;
  };
  // const [currentOrder, setCurrentOrder] = useState(null);
  return (
    <div>
      {/* <div className="h-screen flex items-center justify-center">
        <div className="text-center">
          {(!reduxOrders ||
            reduxOrders.length === 0) && (
              <div>
                <img
                  src={noorderimage}
                  alt="logo"
                  className="mt-14 w-22 h-22 md:mt-20 md:w-32 md:h-32 lg:mt-24 lg:w-48 lg:h-48 xl:mt-32 xl:w-48 xl:h-48"
                />
              </div>
            )}
        </div>
      </div> */}
      {reduxOrders && reduxOrders.length > 0 ? (
        reduxOrders.map((order) => {
          // Store the current order in a variable
          return (
            <div key={order.id} className="mb-8">
              <div>
                <div className="mx-auto mt-12 bg-white max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-7xl px-4 sm:px-6 lg:px-8 rounded-md shadow-lg">
                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div className="my-5 text-left">
                      <h1 className="text-2xl sm:text-4xl font-bold tracking-tight text-gray-900">
                        Order{" "}
                        <span className="text-black-500">#{order.id}</span>
                      </h1>
                    </div>

                    <div className="flex justify-between mb-6">
                      <div className="text-left">
                        {" "}
                        {/* Updated this line */}
                        <h4 className="text-lg font-semibold text-red-900">
                          Order Status: {order.status}
                        </h4>
                      </div>
                      <div className="text-right">
                        {" "}
                        {/* Updated this line */}
                        <h3 className="text-lg font-semibold text-red-900">
                          Payment Status: {order.paymentMethod}
                        </h3>
                      </div>
                    </div>

                    <div className="flow-root">
                      <ul className="-my-6 divide-y divide-gray-200">
                        {order.items.map((item) => (
                          <li key={item.id} className="flex py-6">
                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                              <img
                                src={item.product.thumbnail}
                                alt={item.product.title}
                                className="h-full w-full object-cover object-center"
                              />
                            </div>

                            <div className="ml-4 flex flex-1 flex-col">
                              <div>
                                <div className="flex justify-between text-base font-medium text-gray-900">
                                  <h3>
                                    <a href={item.product.id}>
                                      {item.product.title}
                                    </a>
                                  </h3>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.product.brand}
                                </p>
                              </div>
                              <div className="flex flex-1 items-end justify-between text-sm">
                                <div className="text-gray-500">
                                  <label
                                    htmlFor="quantity"
                                    className="inline mr-5 text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Qty: {item.quantity}
                                    {/* Size : {item.size.name} */}
                                  </label>
                                </div>

                                <div className="flex">
                                  <button
                                    className="text-blue-500 underline hover:text-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out"
                                    onClick={() => openOrderModal(item.id)}
                                  >
                                    View Full Details
                                  </button>
                                </div>

                                {orderModalStates[item.id] && (
                                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                    {/* ... (your existing modal code) */}
                                    <div className="bg-white p-6 rounded-md shadow-md">
                                      <h2 className="text-xl font-semibold mb-4">
                                        Order Details
                                      </h2>
                                      <div className="border-b mb-4"></div>

                                      <div className="grid grid-cols-2 gap-4">
                                        <div>
                                          <p className="text-gray-700">
                                            Product Name:
                                          </p>
                                          <p className="text-gray-900 font-bold">
                                            {item.product.title}
                                          </p>
                                        </div>
                                        <div>
                                          <p className="text-gray-700">
                                            Quantity:
                                          </p>
                                          <p className="text-gray-900 font-bold">
                                            {item.quantity}
                                          </p>
                                        </div>
                                        <div>
                                          <p className="text-gray-700">
                                            Price:
                                          </p>
                                          <p className="text-gray-900 font-bold">
                                            ₹{item.product.discountPrice}
                                          </p>
                                        </div>
                                        <div>
                                          <p className="text-gray-700">Type:</p>
                                          <p className="text-gray-900 font-bold">
                                            {item.product.brand}
                                          </p>
                                        </div>

                                        {/* Check if size is present and display it */}
                                        {item.size && (
                                          <div>
                                            <p className="text-gray-700">
                                              Size:
                                            </p>
                                            <p className="text-gray-900 font-bold">
                                              {item.size.name}
                                            </p>
                                          </div>
                                        )}

                                        {/* Check if color is present and display it */}
                                        {item.color && (
                                          <div>
                                            <p className="text-gray-700">
                                              Color:
                                            </p>
                                            <p className="text-gray-900 font-bold">
                                              {item.color.name}
                                            </p>
                                          </div>
                                        )}
                                      </div>

                                      <div className="mt-6">
                                        <button
                                          onClick={() =>
                                            closeOrderModal(item.id)
                                          }
                                          className="text-red-500 underline"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div className="flex justify-between my-2 text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>₹ {order.totalAmount}</p>
                    </div>
                    <div className="flex justify-between my-2 text-base font-medium text-gray-900">
                      <p>Total Items in Cart</p>
                      <p>{order.totalItems} items</p>
                    </div>
                    <div className="mb-4">
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping Address :
                      </p>
                    </div>
                    <div className="flex justify-between gap-x-6 px-5 py-5 border-solid border-2 border-gray-200 rounded-md">
                      <div className="flex gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {order.selectedAddress.name}
                          </p>
                          <p className="mt-1 text-xs leading-5 text-gray-500 line-clamp-2">
                            {order.selectedAddress.street},{" "}
                            {order.selectedAddress.pinCode}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {order.selectedAddress.phone}
                          </p>
                          <p className="mt-1 text-xs leading-5 text-gray-500 line-clamp-2">
                            {order.selectedAddress.city}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  borderBottom: "3px solid #ccc",
                  paddingBottom: "20px",
                }}
              >
                <>
                  {order.status === "delivered" &&
                    isWithinSevenDays(order.updatedAt) && (
                      <button
                        onClick={() => {
                          setreturncurrentOrder(order);
                          handleReturnOrder(order.id);
                        }}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        disabled={order.status === "returnInitiated"}
                      >
                        Initiate Return
                      </button>
                    )}

                  {order.status === "dispatched" && (
                    <button
                      onClick={() => {
                        setCurrentOrder(order);
                        handleCancelOrder(order.id);
                      }}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      disabled={order.status === "cancelled"}
                    >
                      {isModalOpen ? "Are you sure?" : "Cancel Order"}
                    </button>
                  )}
                </>
              </div>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Cancellation Reason Modal"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "400px",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                  },
                }}
              >
                <button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    fontSize: "20px",
                    color: "#555",
                  }}
                >
                  &times;
                </button>
                <div>
                  <label
                    htmlFor="cancellationReason"
                    style={{ marginBottom: "10px", display: "block" }}
                  >
                    Reason for Cancellation:
                  </label>
                  <textarea
                    id="cancellationReason"
                    value={cancellationReason}
                    onChange={(e) => setCancellationReason(e.target.value)}
                    rows="5"
                    style={{
                      width: "100%",
                      padding: "8px",
                      fontSize: "14px",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    onClick={() => setCancellationConfirmed(true)}
                    style={{
                      background: "#5bc0de",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Proceed to Confirmation
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={cancellationConfirmed}
                onRequestClose={() => setCancellationConfirmed(false)}
                contentLabel="Confirmation Modal"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "400px",
                    width: "100%",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    textAlign: "center",
                  },
                }}
              >
                <button
                  onClick={() => setCancellationConfirmed(false)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "18px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#777",
                  }}
                >
                  &times;
                </button>
                <div>
                  <p>Are you sure you want to cancel this order?</p>
                  <button
                    onClick={() => handleCancelOrder(order.id)}
                    style={{
                      background: "#d9534f",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      margin: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Yes, Cancel
                  </button>
                  <button
                    onClick={() => setCancellationConfirmed(false)}
                    style={{
                      background: "#5bc0de",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      margin: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    No, Go Back
                  </button>
                </div>
              </Modal>
              {/* return order modals below : */}
              <Modal
                isOpen={isReturnModalOpen}
                onRequestClose={() => setIsReturnModalOpen(false)}
                contentLabel="return Reason Modal"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "400px",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                  },
                }}
              >
                <button
                  onClick={() => setIsReturnModalOpen(false)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    fontSize: "20px",
                    color: "#555",
                  }}
                >
                  &times;
                </button>
                <div>
                  <label
                    htmlFor="returnReason"
                    style={{ marginBottom: "10px", display: "block" }}
                  >
                    Reason for Returning the Order:
                  </label>
                  <textarea
                    id="returnReason"
                    value={returnReason}
                    onChange={(e) => setReturnReason(e.target.value)}
                    rows="5"
                    style={{
                      width: "100%",
                      padding: "8px",
                      fontSize: "14px",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    onClick={() => setReturnConfirmed(true)}
                    style={{
                      background: "#5bc0de",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Proceed to Confirmation
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={returnConfirmed}
                onRequestClose={() => setReturnConfirmed(false)}
                contentLabel="Return Confirmation Modal"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "400px",
                    width: "100%",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    textAlign: "center",
                  },
                }}
              >
                <button
                  onClick={() => setReturnConfirmed(false)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "18px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#777",
                  }}
                >
                  &times;
                </button>
                <div>
                  <p>Are you sure you want to return this order?</p>
                  <button
                    onClick={() => handleReturnOrder(order.id)}
                    style={{
                      background: "#d9534f",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      margin: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Yes, Cancel
                  </button>
                  <button
                    onClick={() => setReturnConfirmed(false)}
                    style={{
                      background: "#5bc0de",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      margin: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    No, Go Back
                  </button>
                </div>
              </Modal>
            </div>
          );
        })
        ):(
          <div>
          <div className="h-screen flex items-center justify-center">
        <div className="text-center"></div>
            <img
              src={noorderimage}
              alt="logo"
              className="mt-14 w-22 h-22 md:mt-20 md:w-32 md:h-32 lg:mt-24 lg:w-48 lg:h-48 xl:mt-32 xl:w-48 xl:h-48"
            />
          </div>
        </div>

        )
        
        
        }
      
      {status === "loading" ? (
        <Grid
          height="80"
          width="80"
          color="rgb(79, 70, 229) "
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      ) : null}
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
}
