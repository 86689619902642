const API_URL = process.env.REACT_APP_URL;
export function createUser(userData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/auth/signup`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: { "content-type": "application/json" },
      });

      // if (!response.ok) {
      //   // If the response status is not OK, reject the promise with an error message
      //   const errorData = await response.json();
      //   reject(new Error(errorData || 'Failed to create user'));
      //   return;
      // }

      const data = await response.json();
      resolve({ data });
    } catch (error) {
      // If there's an exception during the fetch or parsing JSON, reject the promise with an error
      reject(new Error("An error occurred while processing your request"));
    }
  });
}

export const verifyOtp = async (info) => {
  // try {
  //   console.log(info)
  //   const response = await fetch('http://localhost:8080/otp/verifyotp', {
  //     method: 'POST', // Assuming you want to send data via POST
  //     body:JSON.stringify( info) ,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  return new Promise(async (resolve) => {
    const response = await fetch(`/auth/verifyotp`, {
      method: "POST",
      body: JSON.stringify(info),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data, verification: data.verification });
  });

  //   const data = await response.json();
  //   resolve({ data, verification: data.verification });

  // } catch (error) {
  //   throw error;
  // }
};
// authAPI.js
export const resendOtp = async (info) => {
  try {
    const response = await fetch(`/auth/resendotp`, {
      method: "POST",
      body: JSON.stringify(info), // Pass only the email
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export function loginUser(loginInfo) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/auth/login`, {
        method: "POST",
        body: JSON.stringify(loginInfo),
        headers: { "content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
        // console.log(data);
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}
// export function locallog(loginInfo) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await fetch('/otp/login', {
//         method: 'POST',
//         body: JSON.stringify(loginInfo),
//         headers: { 'content-type': 'application/json' },
//       });
//       if (response.ok) {
//         const data = await response.json();
//         resolve({ data, verification: data.verification });
//         console.log(data);
//       } else {
//         const error = await response.text();
//         reject(error);
//       }
//     } catch (error) {
//       reject( error );
//     }

//   });
// }
export function checkAuth() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/auth/check`);
      // console.log(response);
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function signOut(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/auth/logout`);
      if (response.ok) {
        resolve({ data: "success" });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      // console.log(error)
      reject(error);
    }
  });
}

export function resetPasswordRequest(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/auth/reset-password-request`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: { "content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function resetPassword(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/auth/reset-password`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}
