import Footer from "../../features/common/Footer.js";
import NavBar from "../../features/navbar/Navbar.js";
import ShippingPolicy from "../Quicklinks/ShippingPolicy.js";

export const ShippingPolicyPage = () => {
  return (
    <div>
      <NavBar>
        <ShippingPolicy></ShippingPolicy>
      </NavBar>
      <Footer></Footer>
    </div>
  );
};
