import Footer from "../../features/common/Footer.js";
import NavBar from "../../features/navbar/Navbar.js";
import CancellationPolicy from "../Quicklinks/Cancellation.js";

export const CancellationPolicyPage = () => {
  return (
    <div>
      <NavBar>
        <CancellationPolicy></CancellationPolicy>
      </NavBar>
      <Footer></Footer>
    </div>
  );
};
