import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md my-8">
      <h1 className="text-3xl font-semibold mb-6">Privacy Policy</h1>

      <p className="mb-4">
        Welcome to Senamart. We respect your privacy and are committed to
        protecting your personal information. Please read this Privacy Policy
        carefully to understand how we collect, use, and disclose your personal
        information.
      </p>

      <h2 className="text-2xl font-semibold my-6">Information We Collect</h2>

      <p className="mb-4">
        We collect information that you provide directly to us when you use our
        website or interact with us. The types of personal information we may
        collect include your name, email address, shipping address, payment
        information, and any other information you choose to provide.
      </p>

      <h2 className="text-2xl font-semibold my-6">How We Use Your Information</h2>

      <p className="mb-4">
        We use the information we collect for various purposes, including:
      </p>

      <ul className="list-disc ml-8 mb-4">
        <li>Process and fulfill your orders</li>
        <li>Communicate with you about your orders and account</li>
        <li>Provide customer support</li>
        <li>Send you promotional emails and updates</li>
        <li>Improve our website and services</li>
      </ul>

      <h2 className="text-2xl font-semibold my-6">Cookies</h2>

      <p className="mb-4">
        We use cookies and will use similar tracking technologies to enhance your
        experience on our website. Cookies are small files that are placed on
        your computer or mobile device when you visit a website. You can
        disable cookies in your browser settings, but this may affect the
        functionality of our website.
      </p>

      <h2 className="text-2xl font-semibold my-6">Third-Party Services</h2>

      <p className="mb-4">
        We may use third-party services like shiprocket and razorpay to help us operate our business and the
        website, such as payment processors, analytics providers, and
        advertising partners. These third parties may have access to your
        personal information only to perform specific tasks on our behalf and
        are obligated not to disclose or use it for any other purpose.
      </p>

      <h2 className="text-2xl font-semibold my-6">Security</h2>

      <p className="mb-4">
        We take reasonable measures to protect your personal information from
        unauthorized access, disclosure, alteration, and destruction. However,
        no method of transmission over the internet or electronic storage is
        100% secure, and we cannot guarantee its absolute security.
      </p>

      <h2 className="text-2xl font-semibold my-6">
        Changes to This Privacy Policy
      </h2>

      <p className="mb-4">
        We may update this Privacy Policy from time to time. Any changes will
        be posted on this page with the updated date. We encourage you to review
        this Privacy Policy periodically for any changes.
      </p>

      <h2 className="text-2xl font-semibold my-6">Contact Us</h2>

      <p>
        If you have any questions about this Privacy Policy, please contact us
        at <a href="mailto:Jankii.international@gmail.com">Jankii.international@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
