import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectError, selectLoggedInUser } from "../authSlice";
import { Link, Navigate } from "react-router-dom";
import { loginUserAsync } from "../authSlice";
import { set, useForm } from "react-hook-form";
import logoImage from "./Senamartonly.png";
import { useState } from "react";
import LoaderComponent from "./Loader.js";

const Login = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const user = useSelector(selectLoggedInUser);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      {user && user.verification === true && <Navigate to="/" replace={true} />}

      <div className="flex min-h-full items-center justify-center p-4 lg:p-8">
        <div className="sm:w-full sm:max-w-md bg-white p-6 sm:p-8 rounded-md shadow-md">
          <div className="flex items-center justify-center mb-4">
            <img className="h-16 w-auto" src={logoImage} alt="SenaMart Logo" />
          </div>

          <h2 className="text-2xl font-extrabold text-gray-900 mb-4 text-center">
            Log in to Your Account
          </h2>

          <form
            noValidate
            onSubmit={handleSubmit((data) => {
              setLoading(true);

              dispatch(
                loginUserAsync({ email: data.email, password: data.password })
              )
                .then((resultAction) => {
                  // Action was successful
                  const userData = resultAction.payload;

                  // Perform actions after successful login
                  console.log("User data:", userData);

                  // Reset the form or navigate to another page if needed

                  setLoading(false);
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("Error during login:", error);

                  // Display an alert with the error message or the error object
                  alert(`Error during login: ${error.message || error}`);

                  setLoading(false);
                });
            })}
            className="space-y-4"
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email Address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                      message: "Email not valid",
                    },
                  })}
                  type="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                  type="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.password && (
                  <p className="text-red-500">{errors.password.message}</p>
                )}
              </div>
              {error && (
                <p className="text-red-500">{error || error.message}</p>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md font-semibold hover:bg-indigo-500 focus:outline-none focus:ring focus:border-indigo-300"
              >
                Log In
              </button>
            </div>
          </form>
          {loading && <LoaderComponent />}
          
          <p className="mt-4 text-center text-sm text-gray-500">
            Not a member?{" "}
            <Link
              to="/signup"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Create an Account
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};
export default Login;
