import Cart from "../features/cart/Cart";
import NavBar from "../features/navbar/Navbar";

function CartPage() {
    return <div>
        <NavBar>

        <Cart></Cart>
        </NavBar>
    </div>;
}

export default CartPage;