import React from "react";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md my-8">
      <h1 className="text-3xl font-semibold mb-6">Pricing Policy</h1>

      <p className="mb-4">
        At Senamart, we believe in transparency and providing clear information
        about our pricing policies. Please read this Pricing Policy carefully to
        understand how we handle product pricing, shipping charges, and payment
        methods.
      </p>

      <h2 className="text-2xl font-semibold my-6">Product Pricing</h2>

      <p className="mb-4">
        Our product prices include both the cost of the item and applicable
        delivery charges. This ensures a straightforward shopping experience
        without any hidden fees. The total amount you see during checkout covers
        the product cost, shipping fees, and any applicable taxes.
      </p>

      <h2 className="text-2xl font-semibold my-6">Shipping Information</h2>

      <p className="mb-4">
        We partner with Shiprocket to provide reliable and efficient shipping
        services. The estimated delivery time for each product is mentioned on
        the product page. Please note that delivery times may vary based on your
        location.
      </p>

      <h2 className="text-2xl font-semibold my-6">Payment Methods</h2>

      <p className="mb-4">
        Senamart uses Razorpay as our trusted payment gateway to ensure secure
        and seamless transactions. We accept a variety of payment methods,
        including credit cards, debit cards, and other online payment options.
        Your payment information is encrypted and protected to guarantee a
        secure shopping experience.
      </p>

      <h2 className="text-2xl font-semibold my-6">Refund Policy</h2>

      <p className="mb-4">We understand that circumstances may arise where a refund is necessary. Please refer to our <Link to="/refund" className="hover:text-yellow-400 text-blue-500">Refund Policy</Link> for detailed information on our refund process, eligibility criteria, and timelines.</p>


      <h2 className="text-2xl font-semibold my-6">Contact Us</h2>

      <p>
        If you have any questions or concerns regarding our pricing policies,
        feel free to reach out to our customer support team at{" "}
        <a
          className="text-blue-500 hover:underline"
          href="mailto:Jankii.international@gmail.com"
        >
          Jankii.international@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default Pricing;
