import React from 'react';

const AboutUs = () => {
  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <h1 className="text-4xl font-bold mb-4">Welcome to Senamart</h1>
      <p className="text-gray-700 leading-relaxed mb-4">
        Embrace a world of choices at Senamart, where we elevate your journey towards victory with unwavering authority. Our dedication transcends the realm of being a premier online marketplace for top-quality army products; we are committed to cultivating a community of empowered individuals who share a fervor for excellence and resilience.
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        Our emblem, a symbol of strength and commitment, features a meticulously crafted military insignia with the letter 'S' subtly integrated. The color palette, comprising deep green, khaki, and metallic accents, reflects our commitment to quality and sophistication.
      </p>

      <h2 className="text-2xl font-bold mb-2">Premium Army Gear</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Explore a meticulously curated selection of premium army gear that upholds the highest standards of performance and durability.
      </p>

      <h2 className="text-2xl font-bold mb-2">Empowering Community</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Join a community that goes beyond traditional retail, connecting warriors and enthusiasts with a shared commitment to excellence and resilience.
      </p>

      <h2 className="text-2xl font-bold mb-2">Convenient Home Delivery</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Experience the unparalleled convenience of home delivery, bringing our top-quality army products right to your doorstep across the nation.
      </p>

      <h2 className="text-2xl font-bold mb-2">Customer Service Excellence</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Our dedicated customer service team is unwaveringly committed to providing personalized guidance and assistance, ensuring a seamless and gratifying shopping experience for you.
      </p>

      <h2 className="text-2xl font-bold mb-2">Transparent Review System</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Share your invaluable experiences and insights through our transparent review system, contributing to an informed community that values honest feedback.
      </p>

      <h2 className="text-2xl font-bold mb-2">Exclusive Deals and Promotions</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Indulge in access to exclusive deals and promotions, enhancing your Senamart shopping experience with even more rewards.
      </p>

      <p className="text-gray-600">&copy; Copyright Senamart 2023</p>
    </div>
  );
};

export default AboutUs;
