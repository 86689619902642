import { useState, useEffect } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductByIdAsync,
  selectProductById,
  selectProductListStatus,
  submitReviewAsync,
} from "../productSlice";
// import { StarIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { addToCartAsync, selectItems } from "../../cart/cartSlice";
import { selectLoggedInUser } from "../../auth/authSlice";
import { useAlert } from "react-alert";
import { Grid } from "react-loader-spinner";
// import ReviewComponent from './ReviewComponent';
import Protected from "../../auth/components/Protected.js";
import { selectUserInfo } from "../../user/userSlice.js";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetail() {
  const userInfo = useSelector(selectUserInfo);

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const items = useSelector(selectItems);
  const product = useSelector(selectProductById);
  const dispatch = useDispatch();
  const params = useParams();
  const alert = useAlert();
  const status = useSelector(selectProductListStatus);
  const [reviewData, setReviewData] = useState({
    comment: "",
    rating: 0,
    name: userInfo ? userInfo.name : "", // Add the user's name if available, otherwise an empty string
    email: userInfo ? userInfo.email : "",
  });
  const handleReviewSubmit = async () => {
    // Validate review data
    if (!reviewData.comment || reviewData.rating === 0) {
      alert.error("Please provide both a comment and a rating.");
      return;
    }
  
    // Ensure params.id is defined
    if (!params || !params.id) {
      console.error("Invalid product ID.");
      return;
    }
  
    // Check if the user has already submitted a comment for the current product
    if (product.reviews.some((review) => review.email === userInfo?.email)) {
      // Display a warning message using the correct method from react-alert
      alert.show("You have already submitted a comment for this product.", {
        type: "warning",
      });
      return;
    }
  
    try {
      // Dispatch the submitReviewAsync action
      await dispatch(submitReviewAsync({ productId: params.id, ...reviewData }));
  
      // Clear the comment input after successful submission
      setReviewData({
        comment: "",
        rating: 0,
        name: userInfo ? userInfo.name : "",
        email: userInfo ? userInfo.email : "",
      });
  
      // Fetch updated product data (including comments)
      dispatch(fetchProductByIdAsync(params.id));
    } catch (error) {
      console.error("Error submitting review:", error);
      // Handle the error as needed (show an error message, log it, etc.)
    }
  };
  
  // Conditionally render the button based on user authentication status
  const renderAddToCartButton = () => {
    if (userInfo) {
      // User is logged in, show "Add to Cart" button
      return (
        <button
          onClick={handleCart}
          type="submit"
          className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Add to Cart
        </button>
      );
    } else {
      // User is not logged in, provide a link to the login page
      return (
        <div className="mt-10 text-center bg-emerald-400 p-6 rounded-lg">
          <p className="text-xl text-white mb-4">
            To add items to your cart, please&nbsp;
            <Link to="/signup" className="text-indigo-800 font-semibold">
              create an account
            </Link>
            &nbsp;or&nbsp;
            <Link to="/login" className="text-indigo-800 font-semibold">
              log in
            </Link>
            .
          </p>
        </div>
      );
    }
  };

  const handleCart = (e) => {
    e.preventDefault();
  
    // Check if both color and size are selected
    if (!selectedColor || !selectedSize) {
      alert.error("Please select both color and size before adding to cart.");
      return;
    }
  
    if (product && items.findIndex((item) => item.product.id === product.id) < 0) {
      console.log({ items, product });
      const newItem = {
        product: product.id,
        quantity: 1,
        color: selectedColor,
        size: selectedSize,
      };
  
      dispatch(addToCartAsync({ item: newItem, alert }));
    } else {
      alert.error("Item Already added");
    }
  };
  
  useEffect(() => {
    dispatch(fetchProductByIdAsync(params.id));
  }, [dispatch, params.id]);

  return (
    <div className="bg-white">
      {status === "loading" ? (
        <Grid
          height="80"
          width="80"
          color="rgb(79, 70, 229) "
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      ) : null}
      {product && (
        <div className="pt-6">
          <nav aria-label="Breadcrumb">
            <ol className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              {product.breadcrumbs &&
                product.breadcrumbs.map((breadcrumb) => (
                  <li key={breadcrumb.id}>
                    <div className="flex items-center">
                      <a
                        href={breadcrumb.href}
                        className="mr-2 text-sm font-medium text-gray-900"
                      >
                        {breadcrumb.name}
                      </a>
                      <svg
                        width={16}
                        height={20}
                        viewBox="0 0 16 20"
                        fill="currentColor"
                        aria-hidden="true"
                        className="h-5 w-4 text-gray-300"
                      >
                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                      </svg>
                    </div>
                  </li>
                ))}
              {/* <li className="text-sm">
                <a
                  href={product.href}
                  aria-current="page"
                  className="font-medium text-gray-500 hover:text-gray-600"
                >
                  {product.title}
                </a>
              </li> */}
            </ol>
          </nav>

          {/* Image gallery */}
          <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
            <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
              <img
                src={product.images[0]}
                alt={product.title}
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
              <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                <img
                  src={product.images[1]}
                  alt={product.title}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                <img
                  src={product.images[2]}
                  alt={product.title}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
              <img
                src={product.images[3]}
                alt={product.title}
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>

          {/* Product info */}
          <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">
                {product.title}
              </h1>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              <h2 className="sr-only">Product information</h2>
              <p className="text-xl line-through tracking-tight text-gray-900">
                ₹{product.price}
              </p>
              <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8 flex justify-between items-center">
                <p className="text-3xl tracking-tight text-gray-900">
                  ₹{product.discountPrice}
                </p>
                <h1 className="ml-4 flex space-x-1 items-center">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <StarIcon
                      key={star}
                      className={`h-6 w-6 ${
                        product.rating >= star
                          ? "text-yellow-500"
                          : "text-gray-300"
                      }`}
                    />
                  ))}
                </h1>
              </div>

              {/* Reviews */}
              {/* Add ReviewComponent here */}
              {/* <ReviewComponent productId={product.id} reviews={product.reviews} /> */}
              <form className="mt-10">
                {/* Colors */}
                {product.colors && product.colors.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">Color</h3>

                    <RadioGroup
                      value={selectedColor}
                      onChange={setSelectedColor}
                      className="mt-4"
                    >
                      <RadioGroup.Label className="sr-only">
                        Choose a color
                      </RadioGroup.Label>
                      <div className="flex items-center space-x-3">
                        {product.colors.map((color) => (
                          <RadioGroup.Option
                            key={color.name}
                            value={color}
                            className={({ active, checked }) =>
                              classNames(
                                color.selectedClass,
                                active && checked ? "ring ring-offset-1" : "",
                                !active && checked ? "ring-2" : "",
                                "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                              )
                            }
                          >
                            <RadioGroup.Label as="span" className="sr-only">
                              {color.name}
                            </RadioGroup.Label>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                color.class,
                                "h-8 w-8 rounded-full border border-black border-opacity-10"
                              )}
                            />
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                )}

                {/* Sizes */}
                {product.sizes && product.sizes.length > 0 && (
                  <div className="mt-10">
                    <div className="flex items-center justify-between">
                      <h3 className="text-sm font-medium text-gray-900">
                        Size
                      </h3>
                      <a
                        href="#"
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Size guide
                      </a>
                    </div>

                    <RadioGroup
                      value={selectedSize}
                      onChange={setSelectedSize}
                      className="mt-4"
                    >
                      <RadioGroup.Label className="sr-only">
                        Choose a size
                      </RadioGroup.Label>
                      <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                        {product.sizes.map((size) => (
                          <RadioGroup.Option
                            key={size.name}
                            value={size}
                            disabled={!size.inStock}
                            className={({ active }) =>
                              classNames(
                                size.inStock
                                  ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                                  : "cursor-not-allowed bg-gray-50 text-gray-200",
                                active ? "ring-2 ring-indigo-500" : "",
                                "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <RadioGroup.Label as="span">
                                  {size.name}
                                </RadioGroup.Label>
                                {size.inStock ? (
                                  <span
                                    className={classNames(
                                      active ? "border" : "border-2",
                                      checked
                                        ? "border-indigo-500"
                                        : "border-transparent",
                                      "pointer-events-none absolute -inset-px rounded-md"
                                    )}
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                  >
                                    <svg
                                      className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                      viewBox="0 0 100 100"
                                      preserveAspectRatio="none"
                                      stroke="currentColor"
                                    >
                                      <line
                                        x1={0}
                                        y1={100}
                                        x2={100}
                                        y2={0}
                                        vectorEffect="non-scaling-stroke"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                )}

                {renderAddToCartButton()}
              </form>
            </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
              {/* Description and details */}
              <div>
                <h3 className="sr-only">Description</h3>

                <div className="space-y-6">
                  <p className="text-base text-gray-900">
                    {product.description}
                  </p>
                </div>
              </div>

              {product.highlights && (
                <div className="mt-10">
                  <h3 className="text-sm font-medium text-gray-900">
                    Highlights
                  </h3>

                  <div className="mt-4">
                    <ul
                      role="list"
                      className="list-disc space-y-2 pl-4 text-sm"
                    >
                      {product.highlights.map((highlight) => (
                        <li key={highlight} className="text-gray-400">
                          <span className="text-gray-600">{highlight}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              <div className="mt-10">
                <h2 className="text-sm font-medium text-gray-900">Details</h2>

                <div className="mt-4 space-y-6">
                  <p className="text-sm text-gray-600">{product.description}</p>
                </div>
              </div>

              {product.reviews && product.reviews.length > 0 && (
                <div className="mt-16">
                  <h2 className="text-2xl font-bold mb-4">Product Reviews</h2>
                  <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    {product.reviews.map((review, index) => (
                      <div
                        key={index}
                        className="bg-white p-4 border border-gray-300 rounded-md shadow-md"
                      >
                        <p className="font-bold mb-2">{review.name}</p>
                        <div className="flex space-x-1 items-center">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <StarIcon
                              key={star}
                              className={`h-4 w-4 ${
                                review.rating >= star
                                  ? "text-yellow-500"
                                  : "text-gray-300"
                              }`}
                            />
                          ))}
                        </div>
                        <p className="text-gray-600 mt-2">{review.comment}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="mt-10">
                <h3 className="text-lg font-medium text-gray-900">
                  Write a Review
                </h3>

                {userInfo ? (
                  // User is logged in, display the review form
                  <div className="flex items-center mt-2">
                    <p className="text-sm text-gray-500">Rating:</p>
                    <div className="ml-2 flex space-x-1">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <StarIcon
                          key={star}
                          className={`h-5 w-5 ${
                            reviewData.rating >= star
                              ? "text-yellow-500"
                              : "text-gray-300"
                          }`}
                          onClick={() =>
                            setReviewData({ ...reviewData, rating: star })
                          }
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  // User is not logged in, display a link to login or create an account
                  <div className="mt-6 text-center bg-emerald-400 p-3 rounded-lg">
                    <p className="text-sm text-white">
                      To add items to your cart, please&nbsp;
                      <Link
                        to="/signup"
                        className="text-indigo-800 font-semibold"
                      >
                        create an account
                      </Link>
                      &nbsp;or&nbsp;
                      <Link
                        to="/login"
                        className="text-indigo-800 font-semibold"
                      >
                        log in
                      </Link>
                      .
                    </p>
                  </div>
                )}

                {userInfo && (
                  <div>
                    <textarea
                      className="mt-2 p-2 border rounded-md w-full"
                      rows="4"
                      placeholder="Write your review here..."
                      value={reviewData.comment}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          comment: e.target.value,
                        })
                      }
                    ></textarea>
                    <button
                      className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                      onClick={handleReviewSubmit}
                    >
                      Submit Review
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
