import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { verifyOtpAsync, createUserAsync } from "../authSlice";
import { resendOtpAsync } from "../authSlice";
import { selectLoggedInUser } from "../authSlice";
import { Link, Navigate } from "react-router-dom";
import logoImage from "./Senamartonly.png";
import LoaderComponent from "./Loader.js";

export default function Signup() {
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedInUser);
  // const otpverification = useSelector()
  const [resendClicked, setResendClicked] = useState(false);

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleSendOtp = (data) => {
    // Dispatch the createUserAsync action
    setLoading(true);
    return dispatch(
      createUserAsync({
        email: data.email,
        password: data.password,
        addresses: [],
        role: "user",
        name: data.name,
      })
    )
      .then((resultAction) => {
        setLoading(false);
        // Extract the data from the fulfilled action payload
        const userData = resultAction.payload;

        // Now you can access userData, which contains the data from the response
        // alert("User Data:", userData);
        setUserData(userData.message);

        // If createUserAsync is successful, setOtpSent to true
        if(userData.message === "Opt sent to your email"){

          setOtpSent(true);
        }

        // Return a resolved Promise to indicate successful completion
        return Promise.resolve(userData);
      })
      .catch((error) => {
        setLoading(false);
        // Handle errors here
        console.error("Error during user creation:", error);

        // Display an alert with the error message or the error object
        alert(`Error during user creation: ${error.message || error}`);

        // Return a rejected Promise to indicate failure
        return Promise.reject(error);
      });
  };

  // console.log(user);
  const handleVerifyOtp = (data) => {
    setLoading(true);
    dispatch(verifyOtpAsync({ email: data.email, otp: data.otp }))
      .then((response) => {
        setLoading(false);
        // Check if verification is successful
        if (response.data && response.data.verification === true) {
          // If verification is true, set otpVerified to true
          setOtpVerified(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error during OTP verification:", error);
      });
  };

  // const handleResendOtp = (data) => {
  //   dispatch(resendOtpAsync({ email: data.email }))
  //     .then(() => {
  //       // Handle success, you can set any additional state or UI feedback here
  //       console.log("OTP resend successful");
  //     })
  //     .catch((error) => {
  //       console.error("Error during OTP resend:", error);
  //     })
  //     .finally(() => {
  //       setResendClicked(true);
  //     });
  // };
  

  return (
    <>
      {user && user.verification === true && <Navigate to="/" replace={true} />}

      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full bg-white p-6 sm:p-8 rounded-md shadow-md">
          {/* ... rest of your code ... */}
          <div className="text-center">
            <img
              className="mx-auto h-16 w-auto"
              src={logoImage}
              alt="SenaMart Logo"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Create a New Account
            </h2>
          </div>

          <form
            noValidate
            className="mt-8 space-y-6"
            onSubmit={handleSubmit((data, e) => {
              e.preventDefault();
              if (!otpSent) {
                handleSendOtp(data);
              } else if (otpSent && !otpVerified) {
                handleVerifyOtp(data);
              }
              
              // else if (otpSent && !otpVerified && !resendClicked) {
              //   handleResendOtp(data);
              // } 
            })}
          >
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.name && (
                  <p className="text-red-500">{errors.name.message}</p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  {...register("email", {
                    required: "email is required",
                    pattern: {
                      value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                      message: "email not valid",
                    },
                  })}
                  type="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  {...register("password", {
                    required: "password is required",
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                      message: `- at least 8 characters\n
                      - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                      - Can contain special characters`,
                    },
                  })}
                  type="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.password && (
                  <p className="text-red-500">{errors.password.message}</p>
                )}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  {...register("confirmPassword", {
                    required: "confirm password is required",
                    validate: (value, formValues) =>
                      value === formValues.password || "password not matching",
                  })}
                  type="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.confirmPassword && (
                  <p className="text-red-500">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
            </div>
            {/* OTP Input field */}
            {otpSent && !otpVerified && (
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  OTP
                </label>
                <div className="mt-2">
                  <input
                    id="otp"
                    {...register("otp")}
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.otp && (
                    <p className="text-red-500">{errors.otp.message}</p>
                  )}
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md font-semibold hover:bg-indigo-500 focus:outline-none focus:ring focus:border-indigo-300"
              >
                {otpSent && !otpVerified ? "Verify OTP" : "Sign Up"}
              </button>
            </div>
            {/* <div className="mt-2">
              {otpSent && !otpVerified && !resendClicked && (
                <button
                  type="submit"
                  className="block w-full rounded-md bg-gray-300 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-600 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  {resendClicked ? "OTP Sent" : "Resend OTP"}
                </button>
              )}
            </div> */}
          </form>
          {loading && <LoaderComponent />}
          {userData && (
            <div className="mt-4 p-4 border rounded bg-gray-100">
              {/* <p className="text-lg font-semibold mb-2">User Data:</p> */}
              <pre className="whitespace-pre-wrap overflow-auto">
                {JSON.stringify(userData, null, 2)}
              </pre>
            </div>
          )}

          <p className="mt-6 text-center text-sm text-gray-500">
            Already a Member?{" "}
            <Link
              to="/login"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Log In
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
