import React from "react";
import { Link } from "react-router-dom";
const ShippingPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md my-8">
      <h1 className="text-3xl font-semibold mb-6">Shipping Policy</h1>

      <p className="mb-4">
        Thank you for choosing Senamart! Our Shipping Policy provides you with
        all the details you need to know about the shipping and delivery of your
        orders.
      </p>

      <h2 className="text-2xl font-semibold my-6">Order Processing</h2>

      <p className="mb-4">
        All orders will be delivered within 3-15 business days. You will receive an
        email confirmation once your order is shipped, containing the tracking
        information (if applicable).
      </p>

      <h2 className="text-2xl font-semibold my-6">Shipping Times</h2>

      <p className="mb-4">
        The time for delivery will vary based on the delivery agent service
        selected through Shiprocket. Different services may have different
        delivery times, and this information will be provided during the
        checkout process.
      </p>

      <h2 className="text-2xl font-semibold my-6">Tracking Your Order</h2>

      <p className="mb-4">
        Once your order is shipped, you will receive an email with the tracking
        information. You can use this information to track the status of your
        order on the Shiprocket website or the respective courier's website.
      </p>

      <h2 className="text-2xl font-semibold my-6">Returns and Exchanges</h2>

      <p className="mb-4">If you need to return or exchange an item, please refer to our <Link to="/refund" className="hover:text-yellow-400 text-blue-500">Refund Policy</Link> for detailed information on the return process, eligibility criteria, and timelines.</p>


      <h2 className="text-2xl font-semibold my-6">Contact Us</h2>

      <p className="mb-4">
        If you have any questions or concerns about our Shipping Policy, feel
        free to contact our customer support team at{" "}
        <a
          className="text-blue-500 hover:underline"
          href="mailto:Jankii.international@gmail.com"
        >
          Jankii.international@gmail.com
        </a>
        .
      </p>

      <p className="mb-4">
        Please note that our Shipping Policy is subject to change, and any
        updates will be reflected on this page.
      </p>
    </div>
  );
};

export default ShippingPolicy;
